<template>


  <div v-if="shorts_data?.items_shorts">

    <GeneralShortsPageWithPlayer
      :list="shorts_data?.items_shorts"
      :is-ended="is_ended"
      :is-loading="is_loading"
      @loadMore="loadMore"
      />

  </div>


</template>

<script setup>
 
 import { watchArray } from '@vueuse/core'

const route = useRoute();
const router = useRouter();
const id = ref(route.params['id']);
const start = ref(0);
const limit = ref(20);
const is_ended = ref(false);

const { $api } = useNuxtApp();
 
 
const loadData = async function() {     

  const response = await $api.getShorts(
    {
      start: start.value,
      limit: limit.value,
      sortable: 'random',
      current: start.value == 0 ? id.value : null,
    }
  )  
  return response;  
}

const { pending: is_loading,  data: shorts_data } = await useLazyAsyncData('shorts_data_' + id.value , async () => await loadData())


const loadMore = async function() {    
  
  start.value += limit.value;
}


watchArray([start], async ([new_start], added, removed) => {
  console.log('watcher');

  if(new_start == 0){
    shorts_data.value.items_shorts = [];
    is_ended.value = false;
  } 
  
  is_loading.value = true;
  var dt = await loadData();

  if(dt.items_shorts?.length == 0)
    is_ended.value = true;  

  shorts_data.value.items_shorts = [...shorts_data.value.items_shorts, ...dt.items_shorts]

  is_loading.value = false;

}, {deep: true})


 




onMounted(() => {
  useTopbarHiddenMobile().value = true;
  useWarningMigrateHidden().value = true;
  useNavbarThemeRequired().value = 'dark';
})

onBeforeUnmount(() => {
  useTopbarHiddenMobile().value = false;
  useWarningMigrateHidden().value = false;
  useNavbarThemeRequired().value = null;
  if(shorts_data.value){
    shorts_data.value.items_shorts = [];
    shorts_data.value = null;
  }
})
 
</script>

<style scoped>
 
 
</style>